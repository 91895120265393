import React, { useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import {
  AppBar, CssBaseline, Toolbar, Typography, Popper,
  makeStyles, Container, Menu, MenuItem, IconButton, Grid, useMediaQuery
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import AutoComplete from '../components/AutoComplete'



const Master = (props) => {

  const data = useStaticQuery(graphql`
      {
        allDepartament(sort: {order: ASC, fields: title}) {
          nodes {
            id
            title
            slug
          }
        }
        allProduct {
          totalCount
        }
      }
    `)

  // const PRODUCTS_COUNT = data.allProduct.totalCount
  const departaments = data.allDepartament.nodes

  const useStyles = makeStyles(theme => ({
    '@global': {
      body: {
        fontFamily: `${process.env.FONT_FAMILY}`,
        fontSize: `${process.env.FONT_SIZE}`,
      },
      h1: {
        fontSize: '3rem',
        color: `${process.env.COLOR1}`
      },
      h2: {
        fontSize: '2.5rem',
        color: `${process.env.COLOR1}`
      },
      h3: {
        fontSize: '2rem',
        color: `${process.env.COLOR1}`
      },
      h4: {
        fontSize: '1.6rem',
        color: `${process.env.COLOR1}`
      },
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
      a: {
        textDecoration: 'none',
        color: `${process.env.COLOR1}`
      },
      '& a:hover': {
        textDecoration: 'none',
        color: `${process.env.COLOR1}`
      }
    },
    appBar: {
      borderBottom: `1px solid ${process.env.COLOR1}`,
      backgroundColor: `${process.env.COLOR1}`
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
      paddingTop: '6px'
    },
    link: {
      margin: theme.spacing(1, 1.5),
      color: `${process.env.MENU_COLOR}`,
      textDecoration: 'none',
      '&:hover': {
        color: `${process.env.COLOR2}`
      },
      [theme.breakpoints.down('md')]: {
        color: `${process.env.COLOR1}`,
        textDecoration: 'none',
        '&:hover': {
          color: `${process.env.COLOR1}`
        },
      }
    },
    desktop: {
      display: 'block',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    mobile: {
      display: 'block',
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    content: {
      padding: theme.spacing(0, 3, 6),
    },
    footer: {
      '& h4': {
        color: `${process.env.COLOR2}`,
      },
      padding: theme.spacing(3, 2),
      marginTop: 'auto',
      backgroundColor: `${process.env.COLOR1}`,
      '& a': {
        textDecoration: 'none',
        color: `${process.env.FOOTER_COLOR}`
      }
    },
    centerFooter: {
      textAlign: 'center',
      marginTop: '30px'
    },
    liFooter: {
      marginTop: '10px'
    },
    menuButton: {
      color: `${process.env.MENU_COLOR}`
    },
    paper: {
      border: `1px solid ${process.env.MAIN_BACKGROUND_COLOR}`,
      width: '300px',
      borderRadius: '5px',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    space: {
      marginRight: '10px',
      color: `${process.env.MENU_COLOR}`
    },
    menu: {
      marginRight: '10px',
      color: `${process.env.MENU_COLOR}`
    },
    iconFooter: {
      fontSize: '2.5rem'
    },
    logo: {
      width: 'calc(100% - 200px)',
      height: 'auto !important',
    },
    googleMaps: {
      position: 'relative',
      paddingBottom: '75%',
      height: '0',
      overflow: 'hidden',
      iframe: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100% !important',
        height: '100% !important'
      }
    }
  }));

  const classes = useStyles();

  const imageMediaQuery = useMediaQuery('(min-width:373px)');
  let image = <img src={`/case-comunicacao-visual_nav.png`} title={props.title} alt={props.title} />
  if (!imageMediaQuery) {
    image = <img src={`/case-comunicacao-visual_512.png`} title={props.title} alt={props.title} />
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElPopper, setAnchorElPopper] = useState(null)

  const handleClickPopper = (service) => {
    setAnchorElPopper(anchorElPopper ? null : service.currentTarget);
  }

  const handleClickWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?phone=55${process.env.WHATS_APP_NUMBER}`, '_blank');
  }

  const open = Boolean(anchorElPopper)
  const id = open ? 'simple-popper' : undefined

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            <Link to="/" className={classes.link}>
              {image}
            </Link>
          </Typography>
          <nav>
            <div className={classes.desktop}>
              {departaments.map(departament => (
                <Link key={departament.id} variant="button" color="textPrimary" to={`/departamentos/${departament.slug}`} className={classes.link}>
                  {departament.title}
                </Link>
              ))}
              <Link variant="button" color="textPrimary" to="/empresa" className={classes.link} rel="nofollow">
                Empresa
              </Link>
              {/* {PRODUCTS_COUNT > 0 ?
                (<Link variant="button" color="textPrimary" to="/produtos" className={classes.link}>
                  Produtos
                </Link>) : null} */}
              <Link variant="button" color="textPrimary" to="/contato" className={classes.link} rel="nofollow">
                Contato
              </Link>
              <IconButton className={classes.menuButton} color="inherit" aria-label="menu" aria-describedby={id} onClick={handleClickPopper}>
                <SearchIcon />
              </IconButton>
              <IconButton edge="start" className={classes.menuButton} aria-label="menu" onClick={handleClickWhatsApp}>
                <WhatsAppIcon />
              </IconButton>
            </div>
            <div className={classes.mobile}>
              <SearchIcon aria-describedby={id} onClick={handleClickPopper} className={classes.space} />
              <MenuIcon onClick={handleClick} className={classes.menu} />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {departaments.map(departament => (
                  <MenuItem key={departament.id} >
                    <Link variant="button" color="textPrimary" to={`/departamentos/${departament.slug}`} className={classes.link}>
                      {departament.title}
                    </Link>
                  </MenuItem>
                ))}
                <MenuItem>
                  <Link variant="button" color="textPrimary" to="/" className={classes.link}>
                    Início
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link variant="button" color="textPrimary" to="/empresa" className={classes.link} rel="nofollow">
                    Empresa
                  </Link>
                </MenuItem>
                {/* {PRODUCTS_COUNT > 0 ?
                  <MenuItem>
                    <Link variant="button" color="textPrimary" to="/produtos" className={classes.link}>
                      Produtos
                    </Link>
                  </MenuItem> : null} */}
                <MenuItem>
                  <Link variant="button" color="textPrimary" to="/contato" className={classes.link}>
                    Contato
                                    </Link>
                </MenuItem>
                <MenuItem>
                  <a aria-label="WhatsApp" href={`https://api.whatsapp.com/send?phone=55${process.env.WHATS_APP_NUMBER}`} target="_blank" rel="noopener noreferrer" className={classes.link}><WhatsAppIcon /></a>
                </MenuItem>
              </Menu>
            </div>
          </nav>
        </Toolbar>
      </AppBar>
      {/* <Container maxWidth="md" component="main" className={classes.content}> */}
      {props.children}
      {/* </Container> */}
      <Popper id={id} open={open} anchorEl={anchorElPopper}>
        <div className={classes.paper}><AutoComplete /></div>
      </Popper>
      <footer className={classes.footer}>
        <Container maxWidth="md">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <h4>Navegação</h4>
              <ul>
                {departaments.map(departament => (
                  <li key={departament.id} className={classes.liFooter}>
                    <Link to={`/departamentos/${departament.slug}`}>
                      {departament.title}
                    </Link>
                  </li>
                ))}
                <li className={classes.liFooter}>
                  <Link to="/empresa" rel="nofollow">
                    Empresa
                  </Link>
                </li>
                {/* {PRODUCTS_COUNT > 0 ?
                  <li className={classes.liFooter}>
                    <Link to="/produtos">
                      Produtos
                    </Link>
                  </li> : null} */}
              </ul>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <h4>Fale com a gente!</h4>
              {process.env.YOUTUBE_URL !== '#' ?
                (<a href={`${process.env.YOUTUBE_URL}`} target="_blank" rel="nofollow noreferrer">
                  <YouTubeIcon className={classes.iconFooter} />
                </a>) : null}
              {process.env.FACEBOOK_URL !== '#' ?
                (<a href={`${process.env.FACEBOOK_URL}`} target="_blank" rel="nofollow noreferrer">
                  <FacebookIcon className={classes.iconFooter} />
                </a>) : null}
              {process.env.INSTAGRAM_URL !== '#' ?
                (<a href={`${process.env.INSTAGRAM_URL}`} target="_blank" rel="nofollow noreferrer">
                  <InstagramIcon className={classes.iconFooter} />
                </a>) : null}
              <a href={`https://api.whatsapp.com/send?phone=55${process.env.WHATS_APP_NUMBER}`} target="_blank" rel="nofollow noreferrer">
                <WhatsAppIcon className={classes.iconFooter} />
              </a>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <h4>Localização</h4>
              <p>
                {process.env.FOOTER_ADDRESS}<br />{process.env.FOOTER_CITY}
              </p>
              <div className={classes.googleMaps}>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30019.337398962354!2d-42.98605759032091!3d-19.864598844970093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa51a4bd62a16a7%3A0x1ba11c1e287c95a4!2sS%C3%A3o%20Domingos%20do%20Prata%2C%20MG%2C%2035995-000!5e0!3m2!1spt-BR!2sbr!4v1593640995514!5m2!1spt-BR!2sbr" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                <div dangerouslySetInnerHTML={{ __html: process.env.FOOTER_MAP }} />
              </div>
            </Grid>
          </Grid>
          <div className={classes.centerFooter}>
            <p>Copyright &copy; {new Date().getFullYear() === 2017 ? `${new Date().getFullYear()}` : `2017 / ${new Date().getFullYear()}`} - {process.env.SITE_NAME}. Todos os direitos reservados<br />Desenvolvido por <a href="https://www.clicksolucoesweb.com.br" target="_blank" rel="nofollow noreferrer">Click Soluções Web</a></p>
          </div>
        </Container>
      </footer>
    </>
  )
}

export default Master